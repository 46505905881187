<template>
  <div class="common-content detail-container">
    <h1 class="detail-title">{{ detail.title }}</h1>
    <p class="detail-date">{{ detail.pub_date }}</p>
    <div class="detail-desc" v-html="detail.content"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detail: {}
    }
  },
  computed: {},
  methods: {
    getNewsDetail(id) {
      this.$store.dispatch('getNewsDetail', id).then(res => {
        this.detail = res
      })
    },
    getAdminDetail(id) {
      this.$store.dispatch('getAdminDetail', id).then(res => {
        this.detail = res
      })
    },
    getProductDetail(id) {
      this.$store.dispatch('getProduceDetail', id).then(res => {
        this.detail = res
      })
    }
  },
  mounted() {
    if (this.$route.name === 'Newsdetail') {
      // 获取新闻详情
      this.getNewsDetail(this.$route.params.id)
    } else if (this.$route.name === 'Productdetail') {
      // 获取产品案例详情
      this.getProductDetail(this.$route.params.id)
    } else if (this.$route.name === 'Solutiondetail') {
      // 获取人才管理，财税管理，总裁事务等详情
      this.getAdminDetail(this.$route.params.id)
    }
  }
}
</script>
<style lang="less">
.detail-container {
  margin-bottom: 2rem;
  box-shadow: 5px 5px 5px #f2f2f2;
  padding-bottom: 2rem;

  h1 {
    font-weight: normal;
    font-size: 2rem;
    color: @global-black;
  }

  .detail-date {
    font-size: 1.5rem;
  }

  .detail-desc {
    text-align: left;
    text-indent: 2rem;
    padding: 0 8rem;
  }
}

@media screen and (max-width: 768px) {
  .detail-container {
    .detail-desc {
      padding: 0 1rem;
      overflow: hidden;

      img {
        max-width: 100%;
        position: relative;
        left: -2rem;
        overflow: hidden;
      }
    }
  }
}
</style>
